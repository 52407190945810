<template>
	<basic-skeleton-loader v-if="!article" />

	<base-container v-else-if="typeof article === 'object' && article.length === 0">
		<p>No content was found for this page</p>
	</base-container>

	<base-container v-else>
		<component :is="contentType(article.type)" :article="article" />
	</base-container>
</template>

<script>
export default {
	inject: ['api'],
	props: {
		page: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			article: null
		}
	},
	methods: {
		contentType(type) {
			return this.$store.state.articleTypes[type]
		}
	},
	created() {
		const top_level = this.page.top_level
		const page_type = this.page.type
		const url = page_type == 'post' ?  `/content/${this.page.id}` : `/content/${this.page.id}/${this.page.slug}`

		this.api({
			url: url,
			callback: data => {
				this.article = page_type == 'post' ? data[0] : data
			},
			errorCallback: () => {
				this.article = []
				console.error('Error when requesting content')
			}
		})
	}
}
</script>

<style>
/* article img {
	@apply mx-auto max-w-3xl;
} */
article figure img {
	@apply mx-auto max-w-full lg:max-w-2xl;
}
article figure figcaption {
	@apply text-center max-w-3xl mx-auto;
}
</style>