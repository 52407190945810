<template>
	<dialog ref="searchBox" class="px-0 py-4 mt-40 bg-white rounded-lg shadow-md">
		<div class="flex items-center px-6 pb-4 border-b border-gray-200">
			<font-awesome-icon class="mr-4 text-gray-700" icon="fa-solid fa-magnifying-glass" />
			<input v-model="searchInput" maxlength="40" type="text" placeholder="Search issues" class="w-full p-1 text-base font-thin outline-none">
			<button @click="close" class="shadow-sm escape-btn hover:shadow-md"></button>
		</div>
		<div class="px-4 py-4">
			<button class="search-btn"
				:class="{active: !advancedSearch}"
				@click="toggleAdvancedSearch(false)">
				<font-awesome-icon class="mr-1.5" icon="fa-solid fa-list" />
				Simple search
			</button>
			<!-- <button class="search-btn"
				:class="{active: advancedSearch}"
				@click="toggleAdvancedSearch(true)">
				<font-awesome-icon class="mr-1.5" icon="fa-solid fa-sliders" />
				Advanced search
			</button> -->
		</div>
		<div v-if="advancedSearch" class="grid px-4 gap-x-6 gap-y-4 sm:grid-cols-6">
			<div class="sm:col-span-3">
				<label for="doi" class="block text-xs font-medium leading-6 text-gray-900">DOI</label>
				<div class="mt-1">
					<input type="text" name="doi" id="doi" autocomplete="family-name" 
						class="block w-full px-2 py-1 text-xs text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-ch-red sm:text-sm sm:leading-6">
				</div>
			</div>
			<div class="sm:col-span-3">
				<label for="doi" class="block text-xs font-medium leading-6 text-gray-900">Journal issue</label>
				<div class="mt-1">
					<input type="text" name="doi" id="doi" autocomplete="family-name" 
						class="block w-full px-2 py-1 text-xs text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-ch-red sm:text-sm sm:leading-6">
				</div>
			</div>
			<div class="sm:col-span-3">
				<label for="doi" class="block text-xs font-medium leading-6 text-gray-900">Title</label>
				<div class="mt-1">
					<input type="text" name="doi" id="doi" autocomplete="family-name" 
						class="block w-full px-2 py-1 text-xs text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-ch-red sm:text-sm sm:leading-6">
				</div>
			</div>
			<div class="sm:col-span-3">
				<label for="doi" class="block text-xs font-medium leading-6 text-gray-900">Author</label>
				<div class="mt-1">
					<input type="text" name="doi" id="doi" autocomplete="family-name" 
						class="block w-full px-2 py-1 text-xs text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-ch-red sm:text-sm sm:leading-6">
				</div>
			</div>
			<div class="mt-2 mb-2 sm:col-span-3">
				<button type="button" class="flex items-center px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-ch-red hover:bg-ch-dark-red-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-ch-red-100">
					<font-awesome-icon class="mr-2" icon="fa-solid fa-magnifying-glass" />
					Search
				</button>
			</div>
		</div>
		<div v-else class="px-4 pb-6">
			<div>
				<p v-if="!loading && searchResults === null" class="pt-6 text-xl font-light text-center text-gray-500">
				Start typing to search, then press Enter or Go
				</p>
				<p class="pt-6" v-else-if="loading">
					<svg class="w-8 h-8 mx-auto spinning" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"/></svg>
				</p>
				<p v-else-if="!loading && searchResults.length < 1 && !loading" class="mt-6 text-xl font-light text-center text-gray-500">
					No results for "<span class="text-gray-900">{{ currentSearch }}</span>"
				</p>
				<div v-else>
					<p class="block mb-3 text-sm font-medium leading-6 text-gray-900">Results</p>
					<div class="flex flex-col gap-3 overflow-y-scroll max-h-96">
						<router-link v-for="item in searchResults" :key="item.slug" @click="close" :to="item?.page_path + '/' + item?.slug">
							<div class="flex items-center justify-between w-full px-6 py-4 text-black bg-gray-100 rounded-md cursor-pointer">
								<div>
									<p class="mb-2 text-sm trim">{{ item?.title }}</p>
									<p class="block px-2 py-1 text-xs bg-gray-200 rounded-full w-fit text-inherit">{{ item?.category?.name }}</p>
								</div>
								<font-awesome-icon class="mr-2" icon="fa-solid fa-chevron-right" />
							</div>
						</router-link>
					</div>
				</div>
			</div>
			
		</div>
	</dialog>
</template>

<script>
export default {
	inject: ['closeSearchBox', 'api'],
	data() {
		return {
			searchInput: '',
			advancedSearch: false,
			searchResults: null,
			loading: false,
			currentSearch: ''
		}
	},
	methods: {
		open() {
			this.$refs.searchBox.showModal()
			document.getElementsByTagName('html')[0].classList.add('overflow-hidden')
		},
		close() {
			this.$refs.searchBox.close()
			this.searchInput = ''
			this.advancedSearch = false
			this.closeSearchBox()
		},
		toggleAdvancedSearch(type) {
			this.advancedSearch = type
		},
		search() {
			this.loading = true
			this.searchResults = []
			this.currentSearch = this.searchInput
			this.api({
				url: '/content/search?query=' + this.searchInput,
				callback: data => {
					this.searchResults = data
					this.loading = false
				}
			})
		}
	},
	unmounted() {
		document.getElementsByTagName('html')[0].classList.remove('overflow-hidden')
	},
	created() {
		window.addEventListener('keydown', e => e.key === "Escape" ? this.closeSearchBox() : null)
		window.addEventListener('keydown', e => e.key === "Enter" ? this.search() : null)
	}
}
</script>

<style scoped>
dialog {
	width: 600px
}
dialog::backdrop {
	@apply bg-black opacity-50
}
.escape-btn {
	background-image: url("data:image/svg+xml,%3Csvg width='16' height='7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.506 6h3.931V4.986H1.736v-1.39h2.488V2.583H1.736V1.196h2.69V.182H.506V6ZM8.56 1.855h1.18C9.721.818 8.87.102 7.574.102c-1.276 0-2.21.705-2.205 1.762-.003.858.602 1.35 1.585 1.585l.634.159c.633.153.986.335.988.727-.002.426-.406.716-1.03.716-.64 0-1.1-.295-1.14-.878h-1.19c.03 1.259.931 1.91 2.343 1.91 1.42 0 2.256-.68 2.259-1.745-.003-.969-.733-1.483-1.744-1.71l-.523-.125c-.506-.117-.93-.304-.92-.722 0-.375.332-.65.934-.65.588 0 .949.267.994.724ZM15.78 2.219C15.618.875 14.6.102 13.254.102c-1.537 0-2.71 1.086-2.71 2.989 0 1.898 1.153 2.989 2.71 2.989 1.492 0 2.392-.992 2.526-2.063l-1.244-.006c-.117.623-.606.98-1.262.98-.883 0-1.483-.656-1.483-1.9 0-1.21.591-1.9 1.492-1.9.673 0 1.159.389 1.253 1.028h1.244Z' fill='%23334155'/%3E%3C/svg%3E");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 57.1428571429% auto;
	appearance: none;
	flex: none;
	font-size: 0;
	border-radius: .375rem;
	padding: .25rem .375rem;
	width: 1.75rem;
	height: 1.5rem;
	--tw-shadow: 0 1px 2px 0 #0000000d;
	--tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
	--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
	--tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
	--tw-ring-color: #0f172a0d;
}

.search-btn {
	@apply inline-flex mr-4 items-center rounded-md bg-white px-3 py-2 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50
}
.search-btn.active {
	@apply bg-gray-100
}

.spinning {
	animation: spin 1s linear infinite;
}
@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
</style>