<template>
	<the-header></the-header>
	<main class="pt-4 bg-background-gray">
		<router-view v-slot="{ Component }">
			<transition name="router" mode="out-in">
				<component :key="new Date().toString()" :is="Component"></component>
			</transition>
		</router-view>
	</main>
	<back-to-top-button></back-to-top-button>
	<the-footer></the-footer>
	<search-box v-if="searchBoxState" ref="searchBox"></search-box>

	<lightbox-component></lightbox-component>
</template>

<script>
import TheHeader from '@/components/layout/header/TheHeader.vue'
import TheFooter from '@/components/layout/TheFooter.vue';
import BackToTopButton from '@/components/BackToTopButton.vue';
import SearchBox from '@/components/layout/header/search/SearchBox.vue';
import LightboxComponent from '@/components/layout/LightboxComponent.vue';


export default {
	components: { TheHeader, TheFooter, BackToTopButton, SearchBox, LightboxComponent },
	data() {
		return {
			searchBoxState: false
		}
	},
	provide() {
		return {
			openSearchBox: this.openSearchBox,
			closeSearchBox: this.closeSearchBox,
			api: this.api
		}
	},
	methods: {
		openSearchBox() {
			// eslint-disable-next-line no-unused-vars
			const search = new Promise((resolve, reject) => {
				this.searchBoxState = true
				resolve()
			})

			search
				.then(() => {
					this.$refs.searchBox.open()
				})
		},
		closeSearchBox() {
			this.searchBoxState = false
		},
		api({url, method = 'GET', body = null, headers = null, callback, errorCallback = () => {}}) {
			const options = {
				method: method,
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					...headers
				},
				body: body ? JSON.stringify(body) : undefined
			}

			fetch(`${this.$apiPath}${url}`, options)
				.then(response => {
					if (!response.ok) throw new Error(response.statusText)

					return response.json()
				})
				.then(callback)
				.catch(errorCallback)
		}
	},
	created() {
		this.api({
			url: '/meta/issue_titles',
			callback: data => {
				this.$store.commit('setIssueTitles', data)
			}
		})
	}
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
	@apply font-ubuntu;
}

main {
	--header: 132px;
	--nav: 64px;
	--footer: 276px;

	min-height: calc(100vh - var(--header) - var(--nav) - var(--footer));
}

@media all and (min-width: 640px) {
	main {
		--header: 139px;
		--nav: 64px;
		--footer: 276px;

		min-height: calc(100vh - var(--header) - var(--nav) - var(--footer));
	}
}

@media all and (min-width: 768px) {
	main {
		--header: 154.5px;
		--nav: 56px;
		--footer: 276px;

		min-height: calc(100vh - var(--header) - var(--nav) - var(--footer));
	}
}

.article-container {
	@apply flex flex-col
}
</style>

<style scoped>
.router-enter-active,
.router-leave-active {
  transition: all 200ms ease-out;
}

.router-enter-to {
  opacity: 1;
}

.router-enter-from {
  opacity: 0
}

.router-leave-to {
  opacity: 0;
}

.router-leave-from {
  opacity: 1;
}
</style>