import { createStore } from "vuex"

/**
 * Store
 */
const store = createStore({
	state() {
		return {
			pageTypes: {
				'post': 'post-component',
				'blog': 'blog-component'
			},
			contentTypes: {
				'static': 'post-article',
				'issue': 'issue-article',
				'archive': 'archive-article'
			},
			articleTypes: {
				'static': 'post-article',
				'issue': 'issue-single-article'
			},
			issueColors: {
				current: {
					text: 'text-ch-green-current',
					box: 'before:bg-ch-green-current'
				},
				guideline: {
					text: 'text-ch-green-current',
					box: 'before:bg-ch-green-current'
				},
				review: {
					text: 'text-orange-500',
					box: 'before:bg-orange-500'
				},
				ecg: {
					text: 'text-pink-400',
					box: 'before:bg-pink-400'
				}
			},
			issueTitles: {
				/* current: 'Current opinion',
				review: 'Review',
				guideline: 'Guideline',
				ecg: 'I show you an ECG' */
			},
			lightbox: {
				visible: false,
				images: []
			},
		}
	},
	getters: {
		pageTypes(state) {
			return state.pageTypes
		},
		contentTypes(state) {
			return state.contentTypes
		},
		lightbox(state) {
			return state.lightbox
		}

	},
	mutations: {
		setIssueTitles(state, titles) {
			state.issueTitles = titles
		},
		setIssueColors(state, colors) {
			state.issueColors = colors
		},
		setLightboxImages(state, image) {
			state.lightbox.images = image
		},
		toggleLightbox(state, lightboxState) {
			state.lightbox.visible = lightboxState
		}
	}
})

export default store