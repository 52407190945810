<template>
	<transition name="scroll-top">
		<div class="fixed bottom-10 xl:bottom-8 right-10 z-10 bg-ch-dark-red-100 rounded-xl p-3
		shadow-sm grid place-items-center cursor-pointer transition-all active:scale-90 hover:bg-ch-dark-red-200"
		v-if="scrollY > 200"
		@click="backToTop">
			<font-awesome-icon class="text-white text-xl" icon="fa-solid fa-chevron-up" />
		</div>
	</transition>
</template>

<script>
export default {
	data() {
		return {
			scrollTimer: 0,
			scrollY: 0
		}
	},
	methods: {
		handleScroll() {
			if (this.scrollTimer) return

			this.scrollTimer = setTimeout(() => {
				this.scrollY = window.scrollY
				clearTimeout(this.scrollTimer)
				this.scrollTimer = 0
			}, 100)
		},
		backToTop() {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			})
		}
	},	
	mounted() {
		window.addEventListener('scroll', this.handleScroll)
	}
}
</script>

<style scoped>
.animated {
	animation: float 300ms forwards;
}

@keyframes float {
	0% {
		right: -5rem;
	}
	100% {
		right: 2.5rem;
	}
}

.scroll-top-enter-active,
.scroll-top-leave-active {
  transition: all 200ms;
}

.scroll-top-enter-to {
  opacity: 1;
  right: 2.5rem;
}

.scroll-top-enter-from {
  opacity: 0;
  right: -2.5rem;
}

.scroll-top-leave-to {
  opacity: 0;
  right: -2.5rem;
}

.scroll-top-leave-from {
  opacity: 1;
  right: 2.5rem;
}
</style>