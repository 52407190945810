<template>
	<footer class="w-full relative bottom-0 bg-background-gray pt-10">
		<div class="bg-ch-footer-red py-8">
			<div class="bg-white rounded-lg px-2 py-4 pb-6 shadow-article container article-container mx-auto flex items-center">
				<img class="pb-2" src="../../assets/img/ch_footer_logo.png" alt="Cardiologica Hungarica logo">
				<p class="font-light text-xs sm:text-sm text-center mb-1">Copyright &copy; 2017-{{ currentYear }} Promenade Publishing House. All rights reserved.</p>
				<p class="font-light text-xs sm:text-sm text-center">The content on this site is intended for health professionals.</p>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	computed: {
		currentYear() {
			return new Date().getFullYear()
		}
	}
}
</script>

<style scoped>
img {
	max-width: 80px;
}
</style>