<template>
	<router-link 
		class="relative py-2 cursor-pointer last:mr-0"
		:to="to"
		:class="{'router-link-active': menuIsActive}">
		<a class="p-3 transition-all rounded-md hover:bg-white hover:text-gray-600"
			:class="customLinkClass">
			<slot v-if="$slots.default"></slot>
			<template v-else>{{ name }}</template>
		</a>
	</router-link>
</template>

<script>
export default {
	props: {
		name: {
			type: String,
			required: true
		},
		to: {
			type: String,
			required: true
		},
		target: {
			type: String,
			required: false
		},
		customLinkClass: {
			type: String,
			required: false
		}
	},
	computed: {
		menuIsActive() {
			// TODO: router-link-active?
			return this.to !== '/' && this.$route.fullPath.includes(this.to)
		}
	}
}
</script>

<style scoped>
a.router-link-active:not(.dml)::after {
	content: '';
	background-color: white;
	position: absolute;
	width: calc( 100% - 0.75rem - 0.75rem );
	height: 2px;
	left: 50%;
	bottom: 5px;
	transform: translateX(-50%);
}
</style>