<template>
	<h2 class="mb-5 text-2xl font-bold text-gray-500" v-html="article.title"></h2>

	<div class="flex justify-end">
		<div class="flex flex-col">
			<p class="mb-2 text-xl font-bold text-right opinion"
					:style="[textStyle]">
					<span class="block w-[15px] h-[90%] mr-2" :style="backgroundStyle"></span>
					<span>{{ opinionString }}</span>
				</p>
			<a v-if="article.meta.doi" :href="'https://doi.org/' + article.meta.doi" class="text-xs font-medium transition-all cursor-pointer text-ch-red hover:underline">{{ !article.meta.doi.includes('DOI:') ? 'DOI: ' : '' }}{{ article.meta.doi }}</a>
		</div>
	</div>

	<div v-if="article.leading_content" class="pt-6 pb-0 mb-5 bg-white rounded-md">
		<p class="mb-3"><b class="font-medium">Authors</b></p>
		<p class="pb-6 text-xs font-medium text-gray-600 prose-base" v-html="article.leading_content"></p>
	</div>

	<div v-if="article.summary">
		<p class="mb-2"><b class="font-medium">Summary</b></p>
		<p class="text-sm leading-6 prose-base" v-html="article.summary"></p>
	</div>

	<p class="py-5 text-lg font-medium">ISSUE: CARDIOLOGIA HUNGARICA | {{ article.meta.year }} | VOLUME {{ article.meta.volume }}, ISSUE {{ article.meta.issue }}</p>

	<div class="flex flex-col flex-wrap justify-between gap-5 mb-6 sm:flex-row">
		<button v-if="!expanded" @click="expand" class="rounded-md transition-all bg-ch-red hover:bg-ch-dark-red-100 text-white py-1.5 px-5 text-base w-full sm:w-fit">
			Read the full article
		</button>

		
		<div class="flex flex-wrap gap-3 gap-x-4">
			<button v-if="article.meta.pdf" class="rounded-md transition-all border-2 border-ch-red hover:text-white hover:bg-ch-red text-ch-red py-1.5 px-5 text-sm w-full sm:w-fit">
				<a :href="article.meta.pdf" target="_blank">
					<font-awesome-icon class="mr-2" icon="fa-solid fa-file-pdf" />
					Read the PDF
					<sup class="ml-1"><font-awesome-icon style="font-size: .6rem" icon="fa-solid fa-arrow-up-right-from-square" /></sup>
				</a>
			</button>
			<a v-if="article.meta.video" :href="article.meta.video" class="text-center sm:text-left rounded-md transition-all border-2 border-ch-red hover:text-white hover:bg-ch-red text-ch-red py-1.5 px-5 text-sm w-full sm:w-fit">
				<font-awesome-icon class="mr-2" icon="fa-solid fa-video" />
				Watch the video summary
			</a>
			<a v-if="article.meta.live" :href="article.meta.live" class="rounded-md text-center sm:text-left transition-all border-2 border-ch-red hover:text-white hover:bg-ch-red text-ch-red py-1.5 px-5 text-sm w-full sm:w-fit">
				<font-awesome-icon class="mr-2" icon="fa-solid fa-tower-broadcast" />
				Watch CH Live interview
			</a>
		</div>
	</div>

	<transition name="article-expanded">
		<div class="break-words article-inside" v-if="expanded">
			<div class="prose-base prose-ol:list-decimal" v-html="article.content"></div>

			<div v-if="article.tailing_content" class="mt-6 prose-base references prose-a:text-ch-dark-red-100 hover:prose-a:underline">
				<p class="mb-2"><b class="font-medium">References</b></p>
				<p class="text-sm leading-6" v-html="article.tailing_content"></p>
			</div>
		</div>
	</transition>
</template>

<script>
import { updateTitle } from '@/composables/update-title'

export default {
	props: ['article'],
	data() {
		return {
			expanded: true
		}
	},
	computed: {
		opinionString() {
			return this.article?.category?.name
		},
		textStyle() {
			return {
				color: this.article?.category?.meta?.color
			}
		},
		backgroundStyle() {
			return {
				backgroundColor: this.article?.category?.meta?.color
			}
		}
	},
	methods: {
		expand() {
			this.expanded = true

			history.pushState(
				{}, null, this.$route.path + '?article=open'
			)
		}
	},
	created() {
		if (this.$route.query?.article === 'closed') {
			this.expanded = false
		}

		updateTitle(this.article.title)
	}
}
</script>

<style scoped>
.opinion {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
</style>

<style>
.references hr {
	display: none;
}

.article-inside h1,
.article-inside h2,
.article-inside h3,
.article-inside h4,
.article-inside h5,
.article-inside h6 {
	@apply text-ch-red;
}
</style>

<style scoped>
.article-expanded-enter-active,
.article-expanded-leave-active {
  transition: all 500ms ease-out;
}

.article-expanded-enter-to {
  opacity: 1;
}

.article-expanded-enter-from {
  opacity: 0
}

.article-expanded-leave-to {
  opacity: 0;
}

.article-expanded-leave-from {
  opacity: 1;
}
</style>