import { createRouter, createWebHistory } from 'vue-router'

import HomePage from '@/pages/HomePage.vue'
import DynamicRouter from '@/components/DynamicRouter.vue'


/**
 * Router
 */
const router = createRouter({
	history: createWebHistory(import.meta.env.VITE_BASE_URL || '/'),
	scrollBehavior() {
		return {
			top: 0
		}
	},
	routes: [
		{ path: '/', name: 'home', component: HomePage },
		{ path: '/index.php/:year/:month/:day/:slug', name: 'legacy-url', component: () => import('@/pages/LegacyPage.vue'), props: true },
		{ path: '/:pathMatch(.*)*', name: 'dynamic-router', component: DynamicRouter }
	]
})

export default router