<template>
	<div>
		<h2 class="mb-2 text-xl font-bold text-gray-500">{{ issue.title }}</h2>
		<div class="flex justify-end mb-5">
			<div class="flex flex-col">
				<p class="mb-2 text-lg font-bold text-right opinion"
					:style="[textStyle]">
					<span class="block w-[12px] h-[80%] mr-2" :style="backgroundStyle"></span>
					<span>{{ opinionString }}</span>
				</p>
				<a v-if="issue.meta.doi" :href="'https://doi.org/' + issue.meta.doi" class="text-xs font-medium transition-all cursor-pointer text-ch-red hover:underline">{{ !issue.meta.doi.includes('DOI:') ? 'DOI: ' : '' }}{{ issue.meta.doi }}</a>
			</div>
		</div>
		<div :class="graphicalAbstractClassList">
			<p class="py-0 text-sm font-medium text-gray-800 prose-base" v-html="issue.leading_content">
			</p>
			<img @click="showSingle" v-if="issue.meta.graphical_abstract" :src="issue.meta.graphical_abstract" class="transition-all cursor-pointer graphical-abstract w-60 hover:scale-105" alt="">
		</div>
		<div class="flex flex-col flex-wrap gap-5 sm:flex-row">
			<router-link :to="(issue.slug ? $route.fullPath + '/' + issue.slug : '')+'?article=closed'">
				<button class="rounded-md transition-all bg-ch-red hover:bg-ch-dark-red-100 text-white py-1.5 px-5 text-base w-full sm:w-fit">
					<font-awesome-icon class="mr-2" icon="fa-solid fa-newspaper" />
					Read the summary
				</button>
			</router-link>
			<button v-if="issue.meta.pdf" class="rounded-md transition-all bg-ch-red hover:bg-ch-dark-red-100 text-white py-1.5 px-5 text-base w-full sm:w-fit">
				<a :href="issue.meta.pdf" target="_blank">
					<font-awesome-icon class="mr-2" icon="fa-solid fa-file-pdf" />
					Read the PDF
					<sup class="ml-1"><font-awesome-icon style="font-size: .6rem" icon="fa-solid fa-arrow-up-right-from-square" /></sup>
				</a>
			</button>
			<a v-if="issue.meta.video" :href="issue.meta.video" class="text-center sm:text-left rounded-md transition-all bg-ch-red hover:bg-ch-dark-red-100 text-white py-1.5 px-5 text-base w-full sm:w-fit">
				<font-awesome-icon class="mr-2" icon="fa-solid fa-video" />
				Watch the video summary
			</a>
			<a v-if="issue.meta.live" :href="issue.meta.live" class="rounded-md text-center sm:text-left transition-all bg-ch-red hover:bg-ch-dark-red-100 text-white py-1.5 px-5 text-base w-full sm:w-fit">
				<font-awesome-icon class="mr-2" icon="fa-solid fa-tower-broadcast" />
				Watch CH Live interview
			</a>
		</div>
	</div>
</template>

<script>
import { cn } from '@/utils/cn'

export default {
	props: ['article'],
	data() {
		return {
			issue: this.article
		}
	},
	computed: {
		opinionString() {
			/* return this.$store.state.issueTitles[this.issue.meta.opinion] */
			return this.issue?.category?.name
		},
		textStyle() {
			return {
				color: this.issue?.category?.meta?.color
			}
		},
		backgroundStyle() {
			return {
				backgroundColor: this.issue?.category?.meta?.color
			}
		},
		graphicalAbstractClassList() {
			return cn('p-4 py-3 mb-5 bg-gray-100 rounded-md', {
				'flex justify-between items-center': this.issue.meta.graphical_abstract
			})
		}
	},
	methods: {
		showSingle() {
			this.$store.commit('setLightboxImages', this.issue.meta.graphical_abstract)
			this.$store.commit('toggleLightbox', true)
		}
	
	}
}
</script>

<style scoped>
.opinion {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
</style>