<template>
	<div>
		<vue-easy-lightbox
			:visible="visibleRef"
			:imgs="imgsRef"
			:index="indexRef"
			@hide="onHide"
			>
		</vue-easy-lightbox>
	</div>
</template>

<script setup>
import VueEasyLightbox from 'vue-easy-lightbox'
import { ref, computed } from 'vue'

import { useStore } from 'vuex'
const store = useStore();

const visibleRef = computed(() => store.state.lightbox.visible)
const indexRef = ref(0)
const imgsRef = computed(() => store.state.lightbox.images)

const onHide = () => {
	store.commit('toggleLightbox', false)
}
</script>