import { type RouteLocation, useRoute } from 'vue-router'

export const setCanonical = (route: RouteLocation) => {
	const currentLink = document.querySelector('link[rel="canonical"]')

	if (currentLink) {
		document.head.removeChild(currentLink)
	}
	
	const url = import.meta.env.VITE_APP_URL + route.fullPath
	const link = document.createElement('link')

	link.setAttribute('rel', 'canonical')
	link.setAttribute('href', url)

	document.head.appendChild(link)
}