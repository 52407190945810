<template>
	<div :class="[backgroundClass, loaderClass]"
		class="relative overflow-hidden">
		<div :style="shimmerStyle"
			class="shimmer absolute top-0 right-0 bottom-0 left-0">
		</div>

		<div v-if="this.$slots.default" class="absolute left-1/2 top-1/2 translate-x-1/2 -translate-y-1/2 grid place-content-center">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		type: {
			type: String,
			default: 'rectangle'
		},
		backgroundClass: {
			type: String,
			default: 'bg-gray-300'
		},
		cssClass: {
			type: String,
			default: ''
		},
		shimmerColor: {
			type: String,
			default: '#ffffff'
		}
	},
	computed: {
		shimmerStyle() {
			const rgb = this.isHexColor(this.shimmerColor) ? this.hextToRgb(this.shimmerColor) : '#ffffff'

			return {
				backgroundImage: `linear-gradient(90deg, rgba(${rgb}, 0) 0%, rgba(${rgb}, 0.2) 20%, rgba(${rgb}, 0.5) 60%, rgba(${rgb}, 0))`,
			}
		},
		loaderClass() {
			return this.cssClass ? this.cssClass : 'rounded'
		}
	},
	methods: {
		isHexColor(hexColor) {
			const hex = hexColor?.replace('#', '')

			return typeof hexColor === 'string' && hexColor.startsWith('#') && hex.length === 6 && !isNaN(Number('0x' + hex))
		},
		hextToRgb(hex) {
			return `${hex.match(/\w\w/g)?.map((x) => +`0x${x}`)}`
		}
	},
	data() {
		return {

		}
	}
}
</script>

<style scoped>
.shimmer {
	transform: translateX(-100%);
	animation: shimmer 1.4s infinite;
}

@keyframes shimmer {
	100% {
		transform: translateX(100%);
	}
}
</style>