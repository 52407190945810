<template>
	<div class="post-article">
		<h1 v-if="article.title" v-html="article.title"></h1>
		<h2 v-if="article.subtitle" v-html="article.subtitle"></h2>
		<div class="prose-base" v-if="article.content" v-html="article.content"></div>
	</div>
</template>

<script>
export default {
	props: ['article']
}
</script>

<style>
.post-article h1 {
	@apply font-medium md:leading-8 text-2xl md:text-3xl;
}
.post-article h2 {
	@apply text-xl md:text-2xl;
}
.post-article p {
	@apply text-sm md:text-base leading-6;
}

.post-article img {
	@apply w-full;
}
.post-article b {
	font-weight: 500;
}
.post-article li {
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;
}
.post-article li:last-child {
	margin-bottom: 0;
}
.post-article li::before {
	content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='rgba(0,0,0,0.5)' height='0.6em' viewBox='0 0 384 512'%3E%3C!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cpath d='M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z'/%3E%3C/svg%3E");
	margin-right: 1rem;
}
</style>