<template>
	<a class="relative py-2 mr-1 cursor-pointer last:mr-0 dropdown-menu" 
		v-click-outside-element="close"
		@click.prevent="toggle" 
		:class="{'dropdown-open': menuOpen, 'dropdown-active': menuIsActive}">
		<a class="p-3 transition-all rounded-md hover:bg-white hover:text-gray-600">{{ name }}<font-awesome-icon class="dropdown-icon" icon="fa-solid fa-play" /></a>
		<ul class="absolute left-0 z-10 flex flex-col px-1.5 py-2 bg-gray-600 rounded-md shadow-lg dropdown" 
			style="top: 3.3rem; width: max-content;"
			:class="menuOpen ? '' : 'hidden'">
			<component :is="child.type" v-for="child of childrenMenu" :key="child.name" class="z-10 dml" :childrenMenu="child.children" :name="child.name" :to="child.href" />
		</ul>
	</a>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import MenuItem from './MenuItem.vue';
import RecursiveDropdownMenu from './RecursiveDropdownMenu.vue';

export default {
	components: { MenuItem, RecursiveDropdownMenu },
	props: {
		name: {
			type: String,
			required: true
		},
		childrenMenu: {
			type: Array,
			required: true
		},
		to: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			menuOpen: false
		}
	},
	computed: {
		menuIsActive() {
			return this.$route.path.includes(this.to)
		}
	},
	methods: {
		toggle() {
			this.menuOpen = !this.menuOpen
		},
		close() {
			this.menuOpen = false
		}
	}
}
</script>

<style scoped>
.dropdown::before {
	content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='2.1em' fill='rgb(75 85 99)' viewBox='0 0 384 512'%3E%3Cpath d='M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z'/%3E%3C/svg%3E");
	position: absolute;
	top: -1.2rem;
	left: 2.2rem;
	transform: rotate(149deg);
	z-index: 1;
}
.dropdown-open > a {
	@apply bg-white text-gray-600
}
.dropdown-icon {
	transform: rotate(90deg);
	height: 0.6em;
	margin: 0;
	margin-left: .4rem;
	vertical-align: unset;
}
.dropdown-active::after {
	content: '';
	background-color: white;
	position: absolute;
	width: calc( 100% - 0.75rem - 0.75rem );
	height: 2px;
	left: 50%;
	bottom: 5px;
	transform: translateX(-50%);
}
</style>