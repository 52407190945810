<template>
	<div class="container flex items-center mx-auto article-container">
		<base-container class="px-6 py-4">
			<div class="flex flex-col gap-6 md:flex-row">
				<!-- Main content section -->
				<article class="flex-1 bg-white prose-base prose-h1:mb-6 prose-h2:text-xl prose-h2:mb-1 prose-h2:mt-1">
					<!-- <h1 class="text-ch-red">Welcome to Cardiologia Hungarica</h1> -->
					<h2 class="mt-0 mb-2 font-medium">About this Journal</h2>
					<p class="text-base leading-5 text-justify"><i>Cardiologia Hungarica</i> – established in 1972 – is the official peer-reviewed, scientific journal of the <span class="text-ch-red">Hungarian Society of Cardiology.</span> The entire journal is available in print and electronically (Open Access). The publication languages of the journal are Hungarian and English. Cardiologia Hungarica is dedicated to publishing original clinical and basic research on all aspects of cardiovascular diseases, as well as editorials, review articles, and case reports. The target readership includes academic members, specialists, residents, and general practitioners working in the fields of adult and pediatric cardiology, cardiovascular surgery and internal medicine.</p>

					<h2 class="!mb-0 font-medium">Current Issue</h2>
					<p class="mb-0 text-sm">{{ homepage_subtitle }}</p>
					<router-link to="/current-issue">
						<img class="mt-2" :src="homepage_image" alt="">
					</router-link>

					<template v-if="articles">
						<div v-for="article of articles.slice(0,3)" :key="article.name" class="p-4 mb-4 bg-gray-100 rounded-lg">
							<p class="m-0 text-sm font-medium">{{ article.title }}</p>
							<div class="flex flex-wrap items-center justify-between mt-4 gap-y-2">
								<div class="flex flex-wrap order-2 sm:order-1 gap-1.5">
									<router-link :to="article.full_path">
										<button class="rounded-md transition-all bg-ch-red hover:bg-ch-dark-red-100 text-white py-1.5 px-5 text-sm w-full sm:w-fit">
											<font-awesome-icon class="mr-2" icon="fa-solid fa-newspaper" />
											Summary
										</button>
									</router-link>
									<button v-if="article.meta.pdf" class="rounded-md transition-all bg-ch-red hover:bg-ch-dark-red-100 text-white py-1.5 px-5 text-sm w-fit">
										<a :href="article.meta.pdf" target="_blank">
											<font-awesome-icon class="mr-2" icon="fa-solid fa-file-pdf" />
											PDF
											<sup class="ml-1"><font-awesome-icon style="font-size: .6rem" icon="fa-solid fa-arrow-up-right-from-square" /></sup>
										</a>
									</button>
									<a v-if="article.meta.video" :href="article.meta.video" class="rounded-md transition-all bg-ch-red hover:bg-ch-dark-red-100 text-white py-1.5 px-5 text-sm w-fit">
										<font-awesome-icon class="mr-2" icon="fa-solid fa-video" />
										Video summary
									</a>
								</div>
								<p class="!justify-start m-0 text-base font-bold text-left opinion order-1 sm:order-2"
									:style="[textStyle(article)]">
									<span class="block w-[12px] h-4 mr-2" :style="backgroundStyle(article)"></span>
									<span>{{ opinionString(article) }}</span>
								</p>
							</div>
						</div>
					</template>

					<h2 class="font-medium">Recent Issues</h2>
					<div class="grid grid-cols-1 gap-8 mt-2 sm:grid-cols-2 lg:grid-cols-3">
						<router-link :to="`/archive/${article?.parent?.slug}/${article?.slug}`" v-for="article in recent_articles" :key="article.slug">
							<div>
								<img :src="article?.meta?.img" class="m-0">
								<p class="mt-2 text-sm font-light leading-5">
									<span class="text-ch-red">{{ article.meta.title }}</span><br>
									<span>{{ article.meta.subtitle }}</span>
								</p>
							</div>
						</router-link>
					</div>
				</article>
				
				<!-- Aside section -->
				<aside class="w-full p-4 pt-0 prose-sm md:w-1/3 lg:w-2/6">
					<a href="https://mkardio.hu/info.aspx?web_id=E3372E0F7ACD587&sp=312" target="_blank" class="mt-8 text-center">
						<img src="https://httptest.promenade.hu/cardiologia.hungarica.eu/backend/public/api/v1/media/uploads/2024/03/angol_logo_rgb-removebg-preview.png" class="w-1/2 mx-auto mt-0 mb-0">
					</a>
					<p class="mt-1 mb-4">
						<strong>Editor-in-Chief:</strong><br>
						<span class="pl-4">Prof. Robert Gabor Kiss, MD, PhD</span><br>
						<strong>Associate Editors:</strong><br>
						<span class="pl-4">Prof. Zoltan Papp, MD, PhD</span><br>
						<span class="pl-4">Mate Vamos, MD, PhD</span><br>
						<strong>Editor secretary:</strong><br>
						<span class="pl-4">Peter Kupo, MD, PhD</span><br>
					</p>
					<p class="mb-4">
						<strong>Print ISSN:</strong> 0133-5596<br>
						<strong>Online ISSN:</strong> 1588-0230<br>
					</p>
					<p class="mb-4">
						<strong>The Publisher:</strong><br>
						<span class="block pb-2 pl-4 text-balance">Rosegger Kft. - Member of the Promenade Group</span>
						<span class="pl-4">H- 1037 Montevideo str. 7., Budapest, Hungary</span><br>
					</p>
					<p class="mt-6 mb-6">
						<ul class="pl-0 space-y-4">
							<li>
								<router-link class="flex items-center font-medium text-ch-dark-red-100 hover:underline" to="/instructions-for-authors/manuscript-preparation">
									<svg class="w-6 mr-4 fill-ch-dark-red-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M249.6 471.5c10.8 3.8 22.4-4.1 22.4-15.5V78.6c0-4.2-1.6-8.4-5-11C247.4 52 202.4 32 144 32C93.5 32 46.3 45.3 18.1 56.1C6.8 60.5 0 71.7 0 83.8V454.1c0 11.9 12.8 20.2 24.1 16.5C55.6 460.1 105.5 448 144 448c33.9 0 79 14 105.6 23.5zm76.8 0C353 462 398.1 448 432 448c38.5 0 88.4 12.1 119.9 22.6c11.3 3.8 24.1-4.6 24.1-16.5V83.8c0-12.1-6.8-23.3-18.1-27.6C529.7 45.3 482.5 32 432 32c-58.4 0-103.4 20-123 35.6c-3.3 2.6-5 6.8-5 11V456c0 11.4 11.7 19.3 22.4 15.5z"/></svg>
									Author Guidelines
								</router-link>
							</li>
							<li>
								<a target="_blank" href="https://neweditor.olo.hu" class="flex items-center font-medium text-ch-dark-red-100 hover:underline">
									<svg class="w-6 mr-4 fill-ch-dark-red-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M368.4 18.3L312.7 74.1 437.9 199.3l55.7-55.7c21.9-21.9 21.9-57.3 0-79.2L447.6 18.3c-21.9-21.9-57.3-21.9-79.2 0zM288 94.6l-9.2 2.8L134.7 140.6c-19.9 6-35.7 21.2-42.3 41L3.8 445.8c-3.8 11.3-1 23.9 7.3 32.4L164.7 324.7c-3-6.3-4.7-13.3-4.7-20.7c0-26.5 21.5-48 48-48s48 21.5 48 48s-21.5 48-48 48c-7.4 0-14.4-1.7-20.7-4.7L33.7 500.9c8.6 8.3 21.1 11.2 32.4 7.3l264.3-88.6c19.7-6.6 35-22.4 41-42.3l43.2-144.1 2.8-9.2L288 94.6z"/></svg>
									Submission site
								</a>
							</li>
							<li>
								<router-link class="flex items-center font-medium text-ch-dark-red-100 hover:underline" to="/instructions-for-authors/contact-the-editoral-office">
									<svg class="w-6 mr-4 fill-ch-dark-red-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M96 0C60.7 0 32 28.7 32 64V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H96zM208 288h64c44.2 0 80 35.8 80 80c0 8.8-7.2 16-16 16H144c-8.8 0-16-7.2-16-16c0-44.2 35.8-80 80-80zm-32-96a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zM512 80c0-8.8-7.2-16-16-16s-16 7.2-16 16v64c0 8.8 7.2 16 16 16s16-7.2 16-16V80zM496 192c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm16 144c0-8.8-7.2-16-16-16s-16 7.2-16 16v64c0 8.8 7.2 16 16 16s16-7.2 16-16V336z"/></svg>
									Contact the Editorial Office
								</router-link>
							</li>
						</ul>
					</p>
					<p class="mb-4">
						<strong>Indexed in:</strong><br>
						<ul class="mt-0 list-disc">
							<li>Scopus</li>
							<li>Embase</li>
							<li>Compendex</li>
							<li>DOAJ</li>
							<li>Google Scholar</li>
							<li>Research Gate</li>
							<li>Digital Object Identifier (DOI) system</li>
						</ul>
					</p>

					<img class="w-40 mx-auto" src="@/assets/img/promenade_medical_communications.png" alt="Promeande Medical Communications">
				</aside>
			</div>
		</base-container>
	</div>
</template>

<script>
export default {
	inject: ['api'],
	data() {
		return {
			articles: null,
			recent_articles: null,
			homepage_image: null,
			homepage_subtitle: null,
		}
	},
	methods: {
		opinionString(article) {
			/* return this.$store.state.issueTitles[this.issue.meta.opinion] */
			return article?.category?.name
		},
		textStyle(article) {
			return {
				color: article?.category?.meta?.color
			}
		},
		backgroundStyle(article) {
			return {
				backgroundColor: article?.category?.meta?.color
			}
		}
	},
	created() {
		this.api({
			url: `/content/current`,
			callback: data => {
				this.articles = data
			},
			errorCallback: () => {
				console.error('Cant load data')
			}
		})

		this.api({
			url: '/meta/homepage',
			callback: data => {
				this.homepage_image = data?.current_issue_image || ''
				this.homepage_subtitle = data?.current_issue_subtitle || ''
			}
		})

		this.api({
			url: '/issue/homepage',
			callback: data => {
				this.recent_articles = data
			}
		})

		// set the title of the page
		document.title = 'Cardiologia Hungarica'
	}
}
</script>

<style>
@media (min-width: 1100px) {
	main .home-container {
		max-width: 100%;
		padding-inline: 3rem;
	}
}
</style>

<style scoped>
.opinion {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
</style>