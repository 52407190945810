<template>
	<article class="z-0 w-full p-6 mb-5 bg-white rounded-lg shadow-article">
		<slot></slot>
	</article>
</template>

<style>
article h1 {
	@apply text-3xl mb-4
}
article p {
	@apply font-light;
}
article img {
	@apply rounded-md
}
</style>