<template>
	<div class="grid place-items-center mt-20 text-center px-4">
		<h1 class="text-3xl md:text-7xl font-medium">404 Not Found</h1>
		<p class="text-base md:text-xl font-light mb-5">Sorry, we can't seem to find you're looking for</p>
		<router-link to="/">
			<button class="text-lg flex items-center text-ch-red transition-all">
				<font-awesome-icon class="mr-2 transition-all" style="padding-top: 2px" icon="fa-solid fa-arrow-left" />
				Back to home
			</button>
		</router-link>
	</div>
</template>

<style scoped>
button:hover svg {
	@apply mr-3.5;
}
/* hover:underline hover:text-ch-dark-red-100 */
</style>