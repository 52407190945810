<template>
	<div class="relative py-2 group">
		<router-link
		:to="to"
		class="mr-1 cursor-pointer last:mr-0"
		:class="{'router-link-active': menuIsActive}">
			<a class="p-3 transition-all rounded-md hover:bg-white hover:text-gray-600"
				:class="customLinkClass">
				<slot v-if="$slots.default"></slot>
				<template v-else>{{ name }}</template>
			</a>

		</router-link>
		<ul v-if="childrenMenu?.length > 0" class="absolute -top-2 px-1.5 py-2 bg-gray-600 rounded-md shadow-lg left-[calc(100%+0.45rem)] flex w-max z-10 flex-col invisible group-hover:visible" style="transition-delay: 30ms">
			<component :is="child.type" v-for="child of childrenMenu" :key="child.name" class="z-10 dml" :name="child.name" :to="child.href" />
		</ul>
	</div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import MenuItem from './MenuItem.vue';
import ExternalMenuItem from './ExternalMenuItem.vue';

export default {
	components: { MenuItem, ExternalMenuItem },
	props: {
		name: {
			type: String,
			required: true
		},
		to: {
			type: String,
			required: true
		},
		childrenMenu: {
			type: Array,
			required: true
		},
		target: {
			type: String,
			required: false
		},
		customLinkClass: {
			type: String,
			required: false
		}
	},
	computed: {
		menuIsActive() {
			// TODO: router-link-active?
			return this.to !== '/' && this.$route.fullPath.includes(this.to)
		}
	}
}
</script>

<style scoped>
a.router-link-active:not(.dml)::after {
	content: '';
	background-color: white;
	position: absolute;
	width: calc( 100% - 0.75rem - 0.75rem );
	height: 2px;
	left: 50%;
	bottom: 5px;
	transform: translateX(-50%);
}
</style>