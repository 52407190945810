<template>
	<header class="relative z-30 w-full">
		<div class="bg-white">
			<RouterLink to="/" class="container flex items-center py-4 mx-auto">
				<img class="mr-2 md:mr-4 w-28 md:w-32" src="../../../assets/img/ch_header_logo.png" alt="Cardiologia Hungarica">
				<div class="pr-1">
					<p class="mb-1 text-3xl font-thin text-ch-red md:text-4xl font-arial">Cardiologia<br>Hungarica</p>
					<p class="text-xs text-gray-400 md:text-sm font-arial">Scientific Journal of the Hungarian Society of Cardiology</p>
				</div>
			</RouterLink>
		</div>
	</header>
	<nav class="sticky top-0 z-30 bg-cover shadow-lg navigation !font-arial">
		<div class="container hidden mx-auto lg:block">
			<ul class="flex items-center py-2 text-sm font-medium text-white xl:text-base h-14">
				<template v-if="!menu">
					<skeleton-loader class="h-6 mr-6 w-14" />
					<skeleton-loader class="w-24 h-6 mr-6" />
					<skeleton-loader class="w-20 h-6 mr-6" />
					<skeleton-loader class="w-32 h-6" />
					<skeleton-loader class="w-20 h-6 ml-auto" />
				</template>
				<template v-else>
					<component v-for="menuItem of menu" :key="menuItem.name" :is="menuItem.type"
						:name="menuItem.name"
						:to="menuItem.href"
						:childrenMenu="menuItem.children"
						/>
					<search-menu-item />
				</template>
			</ul>
		</div>
		<div class="container mx-auto sm:block lg:hidden">
			<template v-if="!menu">
				<div class="flex items-center justify-between py-2 mx-6 text-base font-medium text-white h-14">
					<skeleton-loader class="w-32 h-6" />
					<skeleton-loader class="w-10 h-6" />
				</div>
			</template>
			<template v-else>
				<MobileMenu :navigation="menu" />
			</template>
		</div>
	</nav>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import MenuItem from './MenuItem.vue'
import ExternalMenuItem from './ExternalMenuItem.vue'
import SearchMenuItem from './search/SearchMenuItem.vue'
import DropdownMenuItem from './DropdownMenuItem.vue'
import FullscreenMenu from './FullscreenMenu.vue'
import SkeletonLoader from '../base/SkeletonLoader.vue'
import MobileMenu from './MobileMenu.vue'

export default {
	components: { MenuItem, ExternalMenuItem, SearchMenuItem, DropdownMenuItem, SkeletonLoader, MobileMenu },
	inject: ['api'],
	data() {
		return {
			menu: null
		}
	},
	created() {
		this.api({
			url: '/layout/header',
			callback: data => {
				this.menu = data
			}
		})
	}
}
</script>

<style scoped>
.navigation {
	background-image: url('../../../assets/img/ch_header_nav_bg.png');
}
</style>