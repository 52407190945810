<template>
	<div class="relative inline py-2 mr-1 cursor-pointer last:mr-0">
		<a @click="openLink" class="p-3 transition-all rounded-md hover:bg-white hover:text-gray-600">
			<slot v-if="$slots.default"></slot>
			<template v-else>{{ name }}</template>
			<sup class="ml-1"><font-awesome-icon icon="fa-solid fa-arrow-up-right-from-square" /></sup>
		</a>
	</div>
</template>

<script>
export default {
	props: {
		name: {
			type: String,
			required: true
		},
		to: {
			type: String,
			required: true
		},
		target: {
			type: String,
			required: false
		},
	},
	methods: {
		openLink() {
			window.open(this.to, '_blank')
		}
	}
}
</script>

<style>
sup {
	font-size: .6rem;
}
</style>